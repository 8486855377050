var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((_vm.props.headers),function(header){return _c('th',{key:header.value,staticClass:"text-center"},[(header.text)?_c('div',{staticClass:"d-inline-flex"},[_c('span',{staticClass:"d-inline-flex align-center"},[(header.filter)?_c('v-menu',{attrs:{"close-on-content-click":false,"close-on-click":header.filter.type !== 'date',"nudge-width":200,"offset-y":""},on:{"close":function($event){return _vm.cancel(header.filter)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._b({staticClass:"mx-1",attrs:{"color":header.filter.value != null && header.filter.value !== '' ? 'error' : 'light',"icon":"","outlined":header.filter.value == null || header.filter.value === '',"small":""},on:{"click":function($event){return _vm.open(header.filter)}}},'v-chip',on,false),[_c('i',{staticClass:"mdi mdi-filter"})])]}}],null,true),model:{value:(header.filter.menu),callback:function ($$v) {_vm.$set(header.filter, "menu", $$v)},expression:"header.filter.menu"}},[_c('v-card',[(header.filter.type === 'text')?_c('div',[(header.filter.methods)?_c('v-row',{staticClass:"mt-2",attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-select',{staticStyle:{"max-width":"200px","margin":"0 auto"},attrs:{"items":header.filter.methods,"label":_vm.$t('dataTable.headers.search_method'),"color":"info"},on:{"change":function($event){return _vm.updateMethod(header, $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('filtering.methods.' + item))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('filtering.methods.' + item))+" ")]}}],null,true),model:{value:(header.filter.method),callback:function ($$v) {_vm.$set(header.filter, "method", $$v)},expression:"header.filter.method"}})],1)],1):_vm._e(),_c('v-row',{attrs:{"align":"center"}},[_c('v-text-field',{directives:[{name:"max-length",rawName:"v-max-length",value:(200),expression:"200"}],staticStyle:{"max-width":"200px","margin":"0 auto"},attrs:{"label":_vm.$t('dataTable.headers.search'),"color":"info"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateFilters(header)}},model:{value:(header.filter.value),callback:function ($$v) {_vm.$set(header.filter, "value", $$v)},expression:"header.filter.value"}})],1)],1):_vm._e(),(header.filter.type === 'number')?_c('div',[_c('v-row',{staticClass:"mt-2",attrs:{"align":"center"}},[_c('v-text-field',{directives:[{name:"max-length",rawName:"v-max-length",value:(200),expression:"200"}],staticStyle:{"max-width":"200px","margin":"0 auto"},attrs:{"label":_vm.$t('dataTable.headers.enter_number'),"type":"number","color":"info"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateFilters(header)}},model:{value:(header.filter.value),callback:function ($$v) {_vm.$set(header.filter, "value", $$v)},expression:"header.filter.value"}})],1)],1):_vm._e(),(header.filter.type === 'date')?_c('div',[(header.filter.methods)?_c('v-row',{staticClass:"mt-2",attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-select',{staticStyle:{"max-width":"200px","margin":"0 auto"},attrs:{"items":header.filter.methods,"label":_vm.$t('dataTable.headers.search_method'),"color":"info"},on:{"change":function($event){return _vm.updateMethod(header, $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('filtering.methods.' + item))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('filtering.methods.' + item))+" ")]}}],null,true),model:{value:(header.filter.method),callback:function ($$v) {_vm.$set(header.filter, "method", $$v)},expression:"header.filter.method"}})],1)],1):_vm._e(),_c('v-row',{attrs:{"align":"center"}},[_c('date-picker',{key:header.filter.method,staticClass:"mb-5",staticStyle:{"max-width":"200px","margin":"0 auto"},attrs:{"placeholder":_vm.$t('dataTable.headers.enter_date'),"value-type":"format","range":header.filter.method === 'between'},model:{value:(header.filter.value),callback:function ($$v) {_vm.$set(header.filter, "value", $$v)},expression:"header.filter.value"}})],1)],1):_vm._e(),(header.filter.type === 'select')?_c('div',[_c('v-row',{staticClass:" mt-2",attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-autocomplete',{staticStyle:{"max-width":"200px","margin":"0 auto"},attrs:{"items":header.filter.options,"label":_vm.$t('dataTable.headers.select'),"color":"info"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateFilters(header)}},scopedSlots:_vm._u([(header.filter.translatable)?{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(header.filter.translation_parent + item))+" ")]}}:null,(header.filter.translatable)?{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(header.filter.translation_parent + item))+" ")]}}:null],null,true),model:{value:(header.filter.value),callback:function ($$v) {_vm.$set(header.filter, "value", $$v)},expression:"header.filter.value"}})],1)],1)],1):_vm._e(),(header.filter.type === 'switch')?_c('div',[_c('v-row',{staticClass:" mt-2"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-switch',{staticStyle:{"max-width":"200px","margin":"0 auto"},attrs:{"label":header.filter.label,"dense":"","inset":"","color":"primary"},model:{value:(header.filter.value),callback:function ($$v) {_vm.$set(header.filter, "value", $$v)},expression:"header.filter.value"}})],1)],1)],1):_vm._e(),_c('v-card-actions',{staticClass:"pb-2 pt-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.cancel(header.filter)}}},[_vm._v(" "+_vm._s(_vm.$t('form.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.updateFilters(header)}}},[_vm._v(" "+_vm._s(_vm.$t('form.search'))+" ")])],1)],1)],1):_vm._e(),_vm._v(" "+_vm._s(_vm.translationKey ? _vm.$t((_vm.translationKey + ".table." + (header.text))) : header.text)+" "),(header.sort)?_c('v-chip',{staticClass:"mx-1",attrs:{"icon":"","small":"","outlined":_vm.options.sortBy[0] !== header.value,"color":_vm.options.sortBy[0] === header.value ? 'error': 'light'},on:{"click":function($event){return _vm.sort(header.value)}}},[(!(_vm.options.sortBy[0] === header.value && _vm.options.sortDesc[0] === true))?_c('i',{staticClass:"mdi mdi-sort-ascending"}):_vm._e(),(_vm.options.sortBy[0] === header.value && _vm.options.sortDesc[0] === true)?_c('i',{staticClass:"mdi mdi-sort-descending"}):_vm._e()]):_vm._e()],1)]):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }