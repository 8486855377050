<template>
  <thead class="v-data-table-header">
    <tr>
      <th v-for="header in props.headers" :key="header.value" class="text-center">
        <div v-if="header.text" class="d-inline-flex">
          <span
            class="d-inline-flex align-center"
          >
            <v-menu
              v-if="header.filter"
              v-model="header.filter.menu"
              :close-on-content-click="false"
              :close-on-click="header.filter.type !== 'date'"
              :nudge-width="200"
              offset-y
              @close="cancel(header.filter)"
            >
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="header.filter.value != null && header.filter.value !== '' ? 'error' : 'light'"
                  icon
                  :outlined="header.filter.value == null || header.filter.value === ''"
                  small
                  class="mx-1"
                  v-bind="on"
                  @click="open(header.filter)"
                >
                  <i class="mdi mdi-filter"></i>
                </v-chip>
              </template>
              <v-card>
                <div v-if="header.filter.type === 'text'">
                  <v-row v-if="header.filter.methods" align="center" class="mt-2">
                    <v-col
                      class="d-flex"
                      cols="12"
                    >
                      <v-select
                        v-model="header.filter.method"
                        :items="header.filter.methods"
                        :label="$t('dataTable.headers.search_method')"
                        color="info"
                        style="max-width: 200px; margin: 0 auto"
                        @change="updateMethod(header, $event)"
                      >
                        <template v-slot:selection="{item}">
                          {{ $t('filtering.methods.' + item) }}
                        </template>
                        <template v-slot:item="{item}">
                          {{ $t('filtering.methods.' + item) }}
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row align="center" class="">
                    <v-text-field
                      v-model="header.filter.value"
                      v-max-length="200"
                      :label="$t('dataTable.headers.search')"
                      color="info"
                      style="max-width: 200px; margin: 0 auto"
                      @keydown.enter="updateFilters(header)"
                    ></v-text-field>
                  </v-row>
                </div>
                <div v-if="header.filter.type === 'number'">
                  <v-row align="center" class="mt-2">
                    <v-text-field
                      v-model="header.filter.value"
                      v-max-length="200"
                      :label="$t('dataTable.headers.enter_number')"
                      type="number"
                      color="info"
                      style="max-width: 200px; margin: 0 auto"
                      @keydown.enter="updateFilters(header)"
                    ></v-text-field>
                  </v-row>
                </div>
                <div v-if="header.filter.type === 'date'">
                  <v-row v-if="header.filter.methods" align="center" class="mt-2">
                    <v-col
                      class="d-flex"
                      cols="12"
                    >
                      <v-select
                        v-model="header.filter.method"
                        :items="header.filter.methods"
                        :label="$t('dataTable.headers.search_method')"
                        color="info"
                        style="max-width: 200px; margin: 0 auto"
                        @change="updateMethod(header, $event)"
                      >
                        <template v-slot:selection="{item}">
                          {{ $t('filtering.methods.' + item) }}
                        </template>
                        <template v-slot:item="{item}">
                          {{ $t('filtering.methods.' + item) }}
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row align="center" class="">
                    <date-picker
                      :key="header.filter.method"
                      v-model="header.filter.value"
                      style="max-width: 200px; margin: 0 auto"
                      class="mb-5"
                      :placeholder="$t('dataTable.headers.enter_date')"
                      value-type="format"
                      :range="header.filter.method === 'between'"
                    ></date-picker>
                  </v-row>
                </div>
                <div v-if="header.filter.type === 'select'">
                  <v-row align="center" class=" mt-2">
                    <v-col
                      class="d-flex"
                      cols="12"
                    >
                      <v-autocomplete
                        v-model="header.filter.value"
                        :items="header.filter.options"
                        :label="$t('dataTable.headers.select')"
                        color="info"
                        style="max-width: 200px; margin: 0 auto"
                        @keydown.enter="updateFilters(header)"
                      >
                        <template v-if="header.filter.translatable" v-slot:selection="{item}">
                          {{ $t(header.filter.translation_parent + item) }}
                        </template>
                        <template v-if="header.filter.translatable" v-slot:item="{item}">
                          {{ $t(header.filter.translation_parent + item) }}
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="header.filter.type === 'switch'">
                  <v-row class=" mt-2">
                    <v-col
                      class="d-flex"
                      cols="12"
                    >
                      <v-switch
                        v-model="header.filter.value"
                        :label="header.filter.label"
                        dense
                        inset
                        style="max-width: 200px; margin: 0 auto"
                        color="primary"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </div>
                <v-card-actions class="pb-2 pt-2">
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    @click="cancel(header.filter)"
                  >
                    {{ $t('form.cancel') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="updateFilters(header)"
                  >
                    {{ $t('form.search') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
            {{ translationKey ? $t(`${translationKey}.table.${header.text}`) : header.text }}
            <v-chip
              v-if="header.sort"
              icon
              small
              :outlined="options.sortBy[0] !== header.value"
              class="mx-1"
              :color="options.sortBy[0] === header.value ? 'error': 'light'"
              @click="sort(header.value)"
            >
              <i v-if="!(options.sortBy[0] === header.value && options.sortDesc[0] === true)" class="mdi mdi-sort-ascending"></i>
              <i v-if="options.sortBy[0] === header.value && options.sortDesc[0] === true" class="mdi mdi-sort-descending"></i>
            </v-chip>
          </span>
        </div>
      </th>
    </tr>
  </thead>
</template>

<script>
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ar-sa'
import DatePicker from 'vue2-datepicker'
import axios from '@axios'

export default {
  name: 'DatatableHeader',
  components: { DatePicker },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
    translationKey: {
      type: String,
      default: () => null,
    },
  },
  emits: ['update:filters'],
  async beforeMount() {
    // eslint-disable-next-line no-restricted-syntax
    for (const header of this.props.headers) {
      if (header.filter && header.filter.methods) {
        // eslint-disable-next-line prefer-destructuring
        header.filter.method = header.filter.methods[0];
      }
      if (header.filter && header.filter.type === 'select' && header.filter.api) {
        // eslint-disable-next-line no-await-in-loop
        header.filter.options = await header.filter.api(axios);
      }
    }
  },
  methods: {
    updateFilters(header) {
      if (header && header.filter) {
        if (header.filter.type === 'switch' && !header.filter.value) {
          header.filter.value = false;
        }
        header.filter.menu = false
      }
      this.$emit('update:filters');
    },
    sort(name) {
      if (this.options.sortBy[0] === name) {
        if (this.options.sortDesc[0] === false) {
          this.options.sortDesc = [true];
        } else {
          this.options.sortBy = [];
          this.options.sortDesc = [];
        }
      } else {
        this.options.sortBy = [name];
        this.options.sortDesc = [false];
      }
      this.updateFilters();
    },
    cancel(filter) {
      const temp = filter.value;
      filter.menu = false;
      filter.value = undefined;
      filter.method = undefined;
      if (filter && filter.methods) {
        // eslint-disable-next-line prefer-destructuring
        filter.method = filter.methods[0];
      }
      if (temp !== filter.value) {
        this.updateFilters();
      }
    },
    open(filter) {
      this.props.headers.forEach(header => {
        if (header.filter) {
          header.filter.menu = false
        }
      });
      filter.menu = true;
    },
    updateMethod(header, event) {
      header.filter.method = event;
      this.$forceUpdate();
    },
  },
}
</script>

<style scoped>
th, td {
  padding: unset !important;
}
</style>
